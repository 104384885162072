import axios from 'axios';
import authHeader from "@/api/auth-header";
import settings from "@/settings";
import { ElLoading } from 'element-plus'

const API_URL = process.env.VUE_APP_BASE_API

class BaseService {
    loadingInstance = null;

    async apiPostRequest(uri, data=null) {
        this.showLoading();
        let result  =  await axios.post(API_URL + uri, data, { headers: authHeader() });
        this.closeLoading();
        return result;
    }
    async apiPutRequest(uri, data=null) {
        this.showLoading();
        let result  =  await axios.put(API_URL + uri, data, { headers: authHeader() });
        this.closeLoading();
        return result;
    }
    async apiGetRequest(uri, page=null, params = null, pagination=true) {
        this.showLoading();
        if(pagination && settings.pagination.size) {
            uri = uri + '?per_page='+settings.pagination.size;
        }else {
            uri = uri + '?';
        }
        if(page) {
            if(page > 1) {
                uri = uri + '&page='+page;
            }
        }
        if(params) {
            params.map(function (param, index){
                uri = uri + '&'+param.key+'='+param.value;
            });
        }
        let result = ""
        try{
            result  =  await axios.get(API_URL + uri, { headers: authHeader() });
        }catch (err) {
            this.closeLoading();
        }
        this.closeLoading();
        return result;
    }
    closeLoading() {
        if(this.loadingInstance) {
            this.loadingInstance.close()
        }
    }
    showLoading() {
        this.closeLoading();
        this.loadingInstance =  ElLoading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)', text: 'Loading..', target:'.el-main'})
    }
}

export default new BaseService();
