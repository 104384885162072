<template>
  &copy; 2021 Realty Terminus (www.realtyterminus.com), All Rights Reserved.
</template>

<script>
    export default {
        name: "FooterMain"
    }
</script>

<style scoped>

</style>
