<template>
  <el-aside
    id="aside-style"
    :width="width"
  >
    <div class="left-menu-burger">
      <el-button
        v-if="isCollapse"
        @click="toggleSidebarView"
      >
        <el-icon><Menu/></el-icon>
      </el-button>
      <el-button
        v-if="!isCollapse"
        @click="toggleSidebarView"
      >
        <el-icon><Menu/></el-icon>
      </el-button>
    </div>
    <div
      v-if="!isCollapse"
      class="logo"
    >
      [LOGO]
    </div>
    <SuperAdmin v-if="is('super-admin')"></SuperAdmin>
    <Realtor v-if="is('realtor')"></Realtor>
    <Others v-if="is('editor')"></Others>

  </el-aside>
</template>

<script>
import UserService from "@/api/user.service";
import DisclaimerService from "@/api/disclaimer.service";
import SuperAdmin from "@/layout/components/Sidebar/SuperAdmin";
import Realtor from "@/layout/components/Sidebar/Realtor";
import Others from "@/layout/components/Sidebar/Others";
import {ElIcon, ElButton} from 'element-plus'
    export default {
        name: "Sidebar",
      components: {Others, Realtor, SuperAdmin, ElIcon, ElButton},
      data() {
            return {
                isCollapse: false,
                width: '225px'
            }
        },        
        methods: {
          async setAccountUrl() {
            return UserService.getUserBoard().then(response => {  
              this.$router.push({ path: "/account/"+response.data.id });
            });
          },
          async setChangepasswordUrl() {
            return UserService.getUserBoard().then(response => {  
              this.$router.push({ path: "/account/"+response.data.id, query: { iflogin: 'y' }});
            });
          },

          async setMlsboardUrl() {
            return UserService.getUserBoard().then(response => {  
              this.$router.push({ path: "/mlsboard/"+response.data.id });
            });
          },
          async setSettingUrl() {
            return UserService.getUserBoard().then(response => {  
              this.$router.push({ path: "/setting/"+response.data.domains[0].id });
            });
          },
          async setDisclaimer() {
            return DisclaimerService.disclaimer(1,1).then(response => { 
              this.$router.push({ path: "/listingdisclaimer/"+response.data.data[0].id });
            });
          },
          toggleSidebarView() {
              this.isCollapse = !this.isCollapse
              this.width = '210px'
              if(this.isCollapse) {
                  this.width = '45px'
              }
          },
          handleOpen(key, keyPath) {
              console.log(key, keyPath)
          },
          handleClose(key, keyPath) {
              console.log(key, keyPath)
          },
          handleSelect(key, keyPath) {
            this.handleClose(key, keyPath)
          }
        }

    }
</script>

<style lang="scss"  scoped>
@import '../../../styles/variables.scss';
    .el-aside {
        color: #fff;
        text-align: left;
        padding: 0;
        background: transparent;
    }
    .el-menu, .el-submenu, .el-menu-item, .element-menu-item  {
      height: $sideBarIemHeight;
      background: transparent !important;
      color: $defaultText;
      margin-top: 16px;
      margin-left: 1px;
    }
    .el-menu > li > * {
      color: $defaultText !important;
    }
    .el-menu > .el-submenu > div > span {
      color: $defaultText !important;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 210px;
        min-height: 400px;
    }
    .el-icon-menu {
      color: $defaultText;
    }
    .left-menu-burger {
      text-align: left;
      margin-bottom: 0;
      margin-top: 5px;
      position: relative;
      display: inline-flex;
      margin-left: 7px;
    }
    .i-style-sidebar {
      width: 25px;
      margin-left:5px;
    }
    .logo {
      font-weight: bold;
      color: #000;
      position: relative;
      display: inline-flex;
      margin: 0 10px;
    }
</style>
