<template>
  <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
  >
    <el-menu-item index="1">
      <i
          class="fa fa-tachometer-alt i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="/dashboard">
          Dashboard
        </router-link>
      </template>
    </el-menu-item>
    <el-submenu index="2">
      <template #title>
        <i
            class="el-icon-menu"
            aria-hidden="true"
        />
        <span>CMS</span>
      </template>
      <el-submenu index="2-1">
        <template #title>
          <span>Listings</span>
        </template>
        <el-menu-item index="2-1-1">
          <router-link to="/listings">
            My Listings
          </router-link>
        </el-menu-item>
        <el-menu-item index="2-1-2">
          Import Listing
        </el-menu-item>
        <el-menu-item index="2-1-3">
          Map Center & Settings
        </el-menu-item>
        <el-menu-item index="2-1-4">
          <i class="fa fa-eye i-style-sidebar" />
          <template #title>
            <router-link to="#" @click="setDisclaimer">
              Listing Disclaimer
            </router-link>
          </template>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="2-2">
        <i class="fa fa-list i-style-sidebar" />
        <template #title>
          <router-link to="/blog">
            Blog
          </router-link>
        </template>
      </el-menu-item>
      <el-menu-item index="2-3">
        <i class="fa fa-list i-style-sidebar" />
        <template #title>
          <router-link to="/building">
            Buildings
          </router-link>
        </template>
      </el-menu-item>
      <el-menu-item index="2-4">
        <i class="fa fa-list i-style-sidebar" />
        <template #title>
          <router-link to="/community">
            Communities
          </router-link>
        </template>
      </el-menu-item>
      <el-menu-item index="2-5">
        <i class="fa fa-list i-style-sidebar" />
        <template #title>
          <router-link to="/news">
            News
          </router-link>
        </template>
      </el-menu-item>
    </el-submenu>
    <el-menu-item index="10">
      <i
          class="fa fa-list i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="/testimonial">
          Testimonials
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="9">
      <i
          class="fa fa-list i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="/information">
          Information
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="3">
      <i class="fa fa-user i-style-sidebar" />
      <template #title>
        <router-link to="#" @click="setAccountUrl">
          Account
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="12">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="#" @click="setMlsboardUrl">
          MLS®/Board user ID
        </router-link>
      </template>
    </el-menu-item>

    <el-menu-item index="13">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="#" @click="setChangepasswordUrl">
          Change Password
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="14">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="/domains">
          Domains
        </router-link>
      </template>
    </el-menu-item>

    <el-menu-item index="4">
      <i class="fa fa-users i-style-sidebar" />
      <template #title>
        <router-link to="/leads">
          Leads
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="5">
      <i class="fa fa-users  i-style-sidebar" />
      <template #title>
        <router-link to="/myteam">
          My Team
        </router-link>
      </template>
    </el-menu-item>

    <el-menu-item index="6">
      <i class="el-icon-s-marketing" />
      <template #title>
        <router-link to="/login">
          Social Media
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="7">
      <i
          class="fa fa-list i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="/gallery">
          Gallery
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="11">
      <i
          class="fa fa-unlock i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="#" @click="setSettingUrl">
          Setting
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="15">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="/custom-style">
          Custom Style & Script
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="16">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="/setting-images">
          Images Settings
        </router-link>
      </template>
    </el-menu-item>
    <el-menu-item index="17">
      <i class="fa fa-list i-style-sidebar" />
      <template #title>
        <router-link to="/banners">
          Banner
        </router-link>
      </template>
    </el-menu-item>

    <el-menu-item index="8">
      <i
          class="fa fa-unlock i-style-sidebar"
          aria-hidden="true"
      />
      <template #title>
        <router-link to="/logout">
          Logout
        </router-link>
      </template>
    </el-menu-item>

    <!--          <el-submenu index="4">-->
    <!--              <template #title>-->
    <!--                  <i class="el-icon-location"></i>-->
    <!--                  <span>Navigator One</span>-->
    <!--              </template>-->
    <!--              <el-menu-item-group>-->
    <!--                  <template #title><span>Group One</span></template>-->
    <!--                  <el-menu-item index="1-1">item one</el-menu-item>-->
    <!--                  <el-menu-item index="1-2">item two</el-menu-item>-->
    <!--              </el-menu-item-group>-->
    <!--              <el-menu-item-group title="Group Two">-->
    <!--                  <el-menu-item index="1-3">item three</el-menu-item>-->
    <!--              </el-menu-item-group>-->
    <!--              <el-submenu index="1-4">-->
    <!--                  <template #title><span>item four</span></template>-->
    <!--                  <el-menu-item index="1-4-1">item one</el-menu-item>-->
    <!--              </el-submenu>-->
    <!--          </el-submenu>-->
  </el-menu>
</template>

<script>
export default {
  name: "Others",
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
  }
}
</script>

<style scoped>

</style>
