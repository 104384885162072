<template>
  <div class="wall">
    <div class="wall-image" />
  </div>
  <div class="common-layout">
    <el-container>
      <sidebar />
      <el-container>
        <el-header>
          <header-home />
        </el-header>
        <el-main>
          <navbar />
          <router-view />
        </el-main>
        <el-footer>
          <footer-main />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import Sidebar from "./components/Sidebar"
  import HeaderHome from "./components/Header"
  import Navbar from "./components/Navbar"
  import FooterMain from "./components/Footer"

  export default {
    name: 'Layout',
    components: {
      FooterMain,
      HeaderHome,
      Sidebar,
      Navbar
    },
    computed: {
      key() {
        return this.$route.path
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
  .wall {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .wall-image {
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    opacity: 85%;
    background-color: $bodyBg;
    //background-image: url(../assets/sf-bg.jpeg);
  }
  .app-main {
    /* 50= navbar  50  */
    min-height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .fixed-header+.app-main {
    padding-top: 50px;
  }

  .hasTagsView {
    .app-main {
      /* 84 = navbar + tags-view = 50 + 34 */
      min-height: calc(100vh - 84px);
    }

    .fixed-header+.app-main {
      padding-top: 84px;
    }
  }

  .el-container {
    min-height: 1000px;
    z-index: 1;
  }
  .el-header {
    background-color: transparent;
    color: #000;
    line-height: 60px;
    text-align: right;
    font-size: 16px;
  }
  .sidebar {
    background: #373737;
  }
  .el-main {
    background-color: #fff;
    width: 96.5%;
  }
</style>

<style lang="scss" scoped>
  // fix css style bug in open el-dialog
  .el-popup-parent--hidden {
    .fixed-header {
      padding-right: 15px;
    }
  }
</style>
