<template>
  <div class="header-search">
    <header-search />
  </div>
  <i
    class="far fa-question-circle"
    aria-hidden="true"
  />
  <i
    class="fas fa-cogs"
    aria-hidden="true"
  />
  <el-dropdown class="dropdown">
    <i
      class="fas fa-th-large"
      aria-hidden="true"
    />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item>View</el-dropdown-item>
        <el-dropdown-item>Add</el-dropdown-item>
        <el-dropdown-item>Delete</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <router-link to="/logout">
    <i class="fas fa-sign-out-alt" />
  </router-link>
  <span v-if="userdata">Welcome {{userdata.name}}</span>
</template>

<script>
import HeaderSearch from "../HeaderSearch/"
    export default {
        name: "HeaderHome",
      components: {
          HeaderSearch
      },
      mounted() {
          this.restoreUserData()
      },
      data() {
          return {
            userdata: null
          }
      },
      methods: {
        restoreUserData() {
          let userdata = JSON.parse(localStorage.getItem('user'))
          if(userdata) {
            this.userdata = userdata
          }
          console.log(this.userdata)
        }
      },
    }
</script>

<style scoped>
.far, .fas {
  margin-right: 20px;
  color: #000;
}
.header-search {
  width:40%;
  position: relative;
  float: left;
  margin-left: 25%;
  overflow: auto;
  line-height: 1.5;
  margin-top: 10px;
}
.dropdown{
  margin-top:22px;
}
</style>
