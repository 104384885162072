<template>
  <el-menu
      :default-active="activeLink"
      @open="handleOpen"
      @close="handleClose"
      active-text-color="#ffd04b"
  >
    <template v-for="(menuItem, i) in menu" :key="menuItem.title">
      <el-menu-item
        v-if="menuItem.linkto"
        :index="menuItem.linkto"
      >
        <template #title>
            <router-link :to="menuItem.linkto" v-if="!menuItem.click">
              <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
              {{menuItem.title}}
            </router-link>
            <router-link to="#" v-if="menuItem.click" @click="handle_function_call(menuItem.click)">
              <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
              {{menuItem.title}}
            </router-link>
        </template>
      </el-menu-item>
      <el-sub-menu :index="''+i+1" v-if="menuItem.submenu">
        <template #title>
          <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
          <span>{{menuItem.title}}</span>
        </template>
        <template v-for="(submenuItem, j) in menuItem.submenu" :key="submenuItem.title">
          <el-menu-item
              :index="submenuItem.linkto"
              v-if="menuItem.submenu && submenuItem.linkto"
          >
            <template #title>
              <router-link :to="submenuItem.linkto" v-if="!submenuItem.click && !submenuItem.submenu">
                <el-icon><component v-bind:is="submenuItem.icon"></component></el-icon>
                {{submenuItem.title}}
              </router-link>
              <router-link to="#" v-if="submenuItem.click" @click="handle_function_call(submenuItem.click)">
                <el-icon><component v-bind:is="submenuItem.icon"></component></el-icon>
                {{submenuItem.title}}
              </router-link>
            </template>
          </el-menu-item>

<!--          Level 3-->
          <el-sub-menu :index="i+1+'-'+j+1" v-if="submenuItem.submenu">
            <template #title>
              <el-icon><component v-bind:is="submenuItem.icon"></component></el-icon>
              <span>{{submenuItem.title}}</span>
            </template>
            <template v-for="(subSubmenuItem) in submenuItem.submenu" :key="subSubmenuItem.title">
              <el-menu-item
                  :index="subSubmenuItem.linkto"
                  v-if="submenuItem.submenu"
              >
                <template #title>
                  <router-link :to="subSubmenuItem.linkto" v-if="!subSubmenuItem.click && !subSubmenuItem.submenu">
                    <el-icon><component v-bind:is="subSubmenuItem.icon"></component></el-icon>
                    {{subSubmenuItem.title}}
                  </router-link>
                  <router-link to="#" v-if="subSubmenuItem.click" @click="handle_function_call(subSubmenuItem.click)">
                    <el-icon><component v-bind:is="subSubmenuItem.icon"></component></el-icon>
                    {{subSubmenuItem.title}}
                  </router-link>
                </template>
              </el-menu-item>
            </template>
          </el-sub-menu>
<!--          Level 3 Ends-->

        </template>
      </el-sub-menu>
    </template>
  </el-menu>
</template>

<script>
import UserService from "@/api/user.service";
import DisclaimerService from "@/api/disclaimer.service";
import {ElIcon, ElMenu, ElSubMenu} from 'element-plus'
export default {
  name: "Realtor",
  components: {ElIcon, ElMenu, ElSubMenu},
  mounted: function(){
    this.activeLink = this.$route.path;
  },
  watch: {
    $route (to, from) {
      this.activeLink = to.path;
    }
  },
  data() {
    return {
      activeLink: null,
      menu: [
        {linkto:'/dashboard', title: 'DASHBOARD', icon:'Grid', submenu: null},
        {linkto:null, title: 'CRM', icon:'DataLine', submenu: [
            {linkto:'/leads', title: 'Leads', icon:'User', submenu: null},
              ]},
        // {linkto:null, title: 'Listings', icon:'', submenu: [
        //     {linkto:'/mls-listings', title: 'MLS Listings', icon:'', submenu: null},
        //     {linkto:'/map-center', title: 'Map Center', icon:'', submenu: null},
        //     {linkto:'#', title: 'Import Listing', icon:'', submenu: null},
        //     {linkto:'#', title: 'Settings', icon:'', submenu: null, click:'setMlsboardUrl'},//MLS Bord like
        //   ]},
        {linkto:null, title: 'CMS', icon:'DataLine', submenu: [
            {linkto:'/blog', title: 'Blog', icon:'EditPen', submenu: null},
            {linkto:'/building', title: 'Buildings', icon:'OfficeBuilding', submenu: null},
            {linkto:'/community', title: 'Communities', icon:'MapLocation', submenu: null},
            {linkto:null, title: 'LISTINGS', icon:'Place', submenu: [
                    {linkto:'/listings', title: 'My Listings', icon:'LocationFilled', submenu: null},
                    // {linkto:'/mls-listings', title: 'MLS Listings', icon:'', submenu: null},
                    // {linkto:'/map-center', title: 'Map Center', icon:'', submenu: null},
                    // {linkto:'#', title: 'Import Listing', icon:'', submenu: null},
                    {linkto:'#', title: 'MLS Board', icon:'DataBoard', submenu: null, click:'setMlsboardUrl'},
                    {linkto:'#', title: 'Disclaimer', icon:'Notebook', submenu: null, click:'setDisclaimer'},
              ]},
            {linkto:'/news', title: 'News', icon:'Memo', submenu: null},
            {linkto:'/testimonial', title: 'Testimonials', icon:'Postcard', submenu: null},

          ]},
        {linkto:null, title: 'WEBSITE', icon:'SetUp', submenu: [
            {linkto:null, title: 'DESIGN', icon:'ScaleToOriginal', submenu: [
                {linkto:'/template', title: 'Template', icon:'Collection', submenu: null},
                {linkto:'/theme', title: 'Theme/Skin', icon:'Picture', submenu: null},
              ]},
            {linkto:'/menu', title: 'Menu', icon:'Menu', submenu: null},//menu not working
            {linkto:'/modules', title: 'Modules', icon:'Folder', submenu: null},
            {linkto:'/pages', title: 'Pages', icon:'Document', submenu: null},
            {linkto:'/information', title: 'Information', icon:'InfoFilled', submenu: null},
            {linkto:null, title: 'CUSTOM', icon:'Operation', submenu: [
                {linkto:'/custom-style', title: 'CSS Style', icon:'Tools', submenu: null},
                {linkto:'/custom-style', title: 'JavaScript', icon:'Tools', submenu: null},
              ]},
          ]},
          
         // {linkto:'/building', title: 'Buildings', icon:'', submenu: null},//add new link
        {linkto:null, title: 'TEAM', icon:'Connection', submenu: [
            {linkto:'/myteam', title: 'Manage Team', icon:'User', submenu: null, click:''},
          ]},
        {linkto:null, title: 'SETTINGS', icon:'Tools', submenu: [
            {linkto:'#', title: 'General', icon:'Tickets', submenu: null, click:'setSettingUrl'},
            {linkto:'/domains', title: 'Domains', icon:'DocumentChecked', submenu: null},
            {linkto:'/setting-images', title: 'Images', icon:'PictureRounded', submenu: null},
          ]},
        {linkto:null, title: 'ACCOUNT', icon:'UserFilled', submenu: [
            {linkto:'/account', title: 'Password', icon:'Key', submenu: null,},
            {linkto:'/profile', title: 'Profile', icon:'Avatar', submenu: null},
            {linkto:'/billing', title: 'Billing Plan', icon:'Money', submenu: null},//add new link
            {linkto:'/invoices', title: 'Invoices', icon:'Wallet', submenu: null},//add new link
          ]},

          // {linkto:'/myteam', title: 'My Team', icon:'', submenu: null},//add new link
          // {linkto:'/gallery', title: 'Gallery', icon:'', submenu: null},//add new link
          // {linkto:'/banners', title: 'Banners', icon:'', submenu: null},//add new link
        {linkto:'/logout', title: 'LOGOUT', icon:'CircleCloseFilled', submenu: null},
      ],
    }
  },
  methods: {
    handle_function_call(function_name) {
      this[function_name]()
    },

     async setMlsboardUrl() {
            return UserService.getUserBoard().then(response => {  
             if (response.data.length===0){
                this.$router.push({ path: "/mlsboard/"});
              }else{  
                this.$router.push({ path: "/mlsboard/"+response.data.id });
              }
            });
          },
      async setSettingUrl() {
          return UserService.getUserBoard().then(response => {  
            this.$router.push({ path: "/setting/"+response.data.domains[0].id });
          });
        },
      async setChangepasswordUrl() {
        return UserService.getUserBoard().then(response => {  
          this.$router.push({ path: "/account", query: { iflogin: 'y' }});
        });
      },
    async setDisclaimer() {
        return DisclaimerService.disclaimer(1,1).then(response => {
          if (response.data.data.length===0){
            this.$router.push({ path: "/listingdisclaimer/"});
          }else{
            this.$router.push({ path: "/listingdisclaimer/"+response.data.data[0].id });
          }
        });
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.scss';
.el-menu, .el-submenu, .el-menu-item, .element-menu-item  {
  height: $sideBarIemHeight;
  line-height: $sideBarIemHeight;
  background: transparent !important;
  color: $defaultText;
  margin-top: 10px;
}
.el-icon {
  color: #2975a5;
}
</style>
