<template>
  <div id="app">
    <router-view />
  </div>
<!--  <router-view/>-->
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.fl-right {
  float: right;
}
.left-align {
  text-align: left;
}
.width-100per {
  width:100%;
}
.fl-left {
  float: left;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.el-form-item__label {
  line-height: 20px;
  padding: 0 0 5px 0 !important;
}

.m-t-10per {
  margin-top: 10%;
}

.m-t-5per {
  margin-top: 5%;
}
</style>
