import BaseService from "./base.service"

class DisclaimerService {  
    disclaimer(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('disclaimer', page, params);
    }  
    get(id) {
        return BaseService.apiGetRequest('disclaimer/'+id);
    }
    add(disclaimer) {
        return BaseService.apiPostRequest('disclaimer/add', disclaimer);
    }

    update(id, disclaimer) {
        return BaseService.apiPostRequest('disclaimer/'+id, disclaimer);
    }
}
export default new DisclaimerService();