import  { createApp } from 'vue'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/styles/element-variables.scss'
import enLang from 'element-plus/lib/locale/lang/en'
import SvgIcon from '@/components/SvgIcon'// svg component

import '@/styles/index.scss' // global css
import './icons' // icon
import App from './App.vue'
import store from './store'
import router from './router'
import LoadScript from "vue-plugin-load-script";


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app
    .use(store)
    .use(router)
    .use(LaravelPermissionToVueJS)
    .use(ElementPlus, { size: 'small', zIndex: 3000 })
    .use(LoadScript)
    .component('SvgIcon', SvgIcon)
    .mount('#app')
