import BaseService from "./base.service";

class UserService {
    getPublicContent() {
        return BaseService.apiGetRequest('all');
    }

    getUserBoard() {
        return BaseService.apiGetRequest('user');
    }

    getModeratorBoard() {
        return BaseService.apiGetRequest('mod');
    }
    getAdminBoard() {
        return BaseService.apiGetRequest('admin');
    }
    update(id, domains) {
        return BaseService.apiPostRequest('domains/'+id, domains);
    }
}

export default new UserService();
