<template>
  <el-menu
      :default-active="activeLink"
      @open="handleOpen"
      @close="handleClose"
      active-text-color="#ffd04b"
  >
    <template v-for="(menuItem, i) in menu" :key="menuItem.title">
      <el-menu-item
          v-if="menuItem.linkto"
          :index="menuItem.linkto"
      >
        <template #title>
           <router-link :to="menuItem.linkto" v-if="!menuItem.click">
             <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
              {{menuItem.title}}
            </router-link>
            <router-link to="#" v-if="menuItem.click" @click="handle_function_call(menuItem.click)">
              <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
              {{menuItem.title}}
            </router-link>
        </template>
      </el-menu-item>
      <el-sub-menu :index="''+i+1" v-if="menuItem.submenu">
        <template #title>
          <el-icon><component v-bind:is="menuItem.icon"></component></el-icon>
          <span>{{menuItem.title}}</span>
        </template>
        <el-menu-item
            v-for="(submenuItem) in menuItem.submenu"
            :key="submenuItem.title"
            :index="submenuItem.linkto"
        >
          <template #title>
            <router-link :to="submenuItem.linkto" v-if="!submenuItem.click">
              <el-icon><component v-bind:is="submenuItem.icon"></component></el-icon>
              {{submenuItem.title}}
            </router-link>
            <router-link to="#" v-if="submenuItem.click" @click="handle_function_call(submenuItem.click)">
              <el-icon><component v-bind:is="submenuItem.icon"></component></el-icon>
              {{submenuItem.title}}
            </router-link>
          </template>
        </el-menu-item>
      </el-sub-menu>
    </template>
  </el-menu>
</template>

<script>
import {ElIcon} from 'element-plus'
export default {
  name: "SuperAdmin",
  components: {
    ElIcon
  },
  mounted: function(){
    this.activeLink = this.$route.path;
  },
  watch: {
    $route (to, from) {
      this.activeLink = to.path;
    }
  },
  data() {
    return {
      activeLink: null,
      menu: [
          {linkto:'/dashboard', title: 'Dashboard', icon:'Grid', submenu: null},
          {linkto:'/users', title: 'Users', icon:'UserFilled', submenu: null},//not working
          {linkto:null, title: 'CMS', icon:'DataLine', submenu: [
              {linkto:'/template', title: 'Manage Templates', icon:'Collection', submenu: null},
              {linkto:'/modules', title: 'Manage Modules', icon:'Folder', submenu: null},
              {linkto:'/pages', title: 'Manage Pages', icon:'Document', submenu: null},
              {linkto:'/roles', title: 'Manage Roles', icon:'Avatar', submenu: null},//not working
              {linkto:'/permissions', title: 'Manage Permissions', icon:'Key', submenu: null}//not working
          ]},
          {linkto:null, title: 'Billing', icon:'Money', submenu: [
              {linkto:'/customers', title: 'Customers', icon:'User', submenu: null},//not working
              {linkto:'/invoices', title: 'Invoices', icon:'Wallet', submenu: null},
          ]},
          {linkto:null, title: 'Products', icon:'Goods', submenu: [
              {linkto:'/products', title: 'Manage Products', icon:'ShoppingBag', submenu: null},
              {linkto:'/prices', title: 'Manage Prices', icon:'PriceTag', submenu: null},
          ]},
          {linkto:'/account', title: 'Account', icon:'UserFilled', submenu: null},
          {linkto:'/logout', title: 'Logout', icon:'CircleCloseFilled', submenu: null},
      ],
    }
  },
  methods: {
     handle_function_call(function_name) {
      this[function_name]()
    },

    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.scss';
.el-menu, .el-submenu, .el-menu-item, .element-menu-item  {
  height: $sideBarIemHeight;
  line-height: $sideBarIemHeight;
  background: transparent !important;
  color: $defaultText;
  margin-top: 10px;
}
</style>
